import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ReviewsCust.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';

export default function ReviewsCust() {
    const navigate = useNavigate()


    return (
        <div className="ReviewsCust">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Customer Reviews | BHO Chimney Sweep - Hear What Our Clients Say</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Customer Reviews | BHO Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Customer Reviews | BHO Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.goldengaragedoor.org/feviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Read customer reviews and testimonials to see why BHO Chimney Sweep is trusted for chimney cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Read customer reviews and testimonials to see why BHO Chimney Sweep is trusted for chimney cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Customer Reviews | BHO Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rainp Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ReviewsCustTitle">
                <h1>What Our Customers Are Saying</h1>
                <h2>Find out why Golden Garage Door is the nation's go-to garage door company. As industry leaders in garage door service and repair, we manage everything from routine installations and maintenance to urgent emergencies requiring immediate attention. Our expert technicians are available around the clock, including weekends and holidays, ensuring your garage door operates smoothly year-round. Trust the professionals at Golden Garage Door to keep your garage door system in top shape. For reliable service you can count on, contact Golden Garage Door today for all your garage door needs.</h2>
            </div>
            <div className="ReviewsCustLsit">

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair was fantastic! They came out the same day and fixed my garage door in no time. Highly recommend their service."</h2>
                    <a href="Garage-Door-Repair">Garage Door Repair</a>
                    <h4>John P.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had an emergency over the weekend, and Golden Garage Door Repair was there within hours. Excellent service, and the technician was super professional!"</h2>
                    <a href="Garage-Door-Off-Track">Garage Door Off Track</a>
                    <h4>Martha G.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I’m impressed with the quality of work and professionalism. My garage door is working like new again, thanks to Golden Garage."</h2>
                    <a href="Garage-Door-Maintenance">Garage Door Maintenance</a>
                    <h4>Mike T.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Their service was top-notch. The team explained everything to me and made sure my door was functioning perfectly. Will definitely use them again."</h2>
                    <a href="Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                    <h4>Emily R.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Great service from start to finish! I would recommend Golden Garage Door Repair to anyone needing a fast and reliable fix."</h2>
                    <a href="Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                    <h4>Pauline W.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The technician arrived on time, was courteous, and fixed my garage door quickly. Couldn’t ask for better service."</h2>
                    <a href="Garage-Door-Opener-Installation">Garage Door Opener Installation</a>
                    <h4>Samuel D.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I’m so glad I called Golden Garage Door Repair. They made the whole process smooth and stress-free. Highly recommended!"</h2>
                    <a href="Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close</a>
                    <h4>Jessica M.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Professional, reliable, and affordable! I’ve had my garage door repaired multiple times, and Golden Garage has always come through."</h2>
                    <a href="Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                    <h4>Chris L.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Super impressed with the quality of service! The technician was knowledgeable and fixed the issue in under an hour."</h2>
                    <a href="Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                    <h4>Karen S.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a broken spring, and Golden Garage Door Repair fixed it in no time. Great price and fast service!"</h2>
                    <a href="Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                    <h4>David J.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I called them for a tune-up, and they were great! My garage door is working better than ever. Highly recommend!"</h2>
                    <a href="Garage-Door-Maintenance">Garage Door Maintenance</a>
                    <h4>Linda B.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair exceeded my expectations. They provided prompt and efficient service. Will definitely use them again."</h2>
                    <a href="Garage-Door-Repair">Garage Door Repair</a>
                    <h4>Tommy R.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Excellent customer service from start to finish. My garage door was stuck, and they came out the same day to fix it."</h2>
                    <a href="Garage-Door-Off-Track">Garage Door Off Track</a>
                    <h4>Angela P.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Affordable and quick. They got the job done right the first time. I’m very happy with Golden Garage Door Repair."</h2>
                    <a href="Garage-Door-Replacement">Garage Door Replacement</a>
                    <h4>Bryan K.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Great company! They helped me pick the right garage door for my home and installed it flawlessly."</h2>
                    <a href="New-Garage-Door-Installation">New Garage Door Installation</a>
                    <h4>Hannah L.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair is amazing! Their customer service was excellent, and the technician did a great job repairing my door."</h2>
                    <a href="Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                    <h4>George V.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Quick and professional service. The technician explained everything to me before starting, and now my door works perfectly."</h2>
                    <a href="Garage-Door-Broken-Rollers">Garage Door Broken Rollers</a>
                    <h4>Denise F.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I’ve used them twice now, and both times, they provided great service. The prices are reasonable, and the staff is friendly."</h2>
                    <a href="Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                    <h4>Oliver N.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Best garage door repair service I’ve used! They came on time and fixed the issue quickly. I’ll definitely recommend them to others."</h2>
                    <a href="Garage-Door-Opener-Remote-Not-Working">Garage Door Opener Remote Is Not Working</a>
                    <h4>Sarah H.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The technician from Golden Garage Door Repair was fantastic! He fixed my door quickly and gave me tips to maintain it."</h2>
                    <a href="Garage-Door-Repair">Garage Door Repair</a>
                    <h4>Richard G.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair is the best! They were quick, professional, and very affordable. I’m extremely happy with their service."</h2>
                    <a href="Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                    <h4>Sophie A.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had an issue with my garage door opener, and they fixed it in no time. Great service and knowledgeable staff."</h2>
                    <a href="Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                    <h4>Jake M.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Couldn’t be happier with the service I received. The technician was friendly, and my garage door works perfectly now."</h2>
                    <a href="Garage-Door-Broken-Hinges">Garage Door Broken Hinges</a>
                    <h4>Vanessa R.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Fast, friendly, and efficient! I will definitely be calling Golden Garage Door Repair again for any future issues."</h2>
                    <a href="Garage-Door-Damaged-Section">Damaged Garage Door Section</a>
                    <h4>James P.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The technician arrived on time and had everything fixed within an hour. I’m really impressed with their professionalism."</h2>
                    <a href="Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair</a>
                    <h4>Ella B.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Fantastic service! The technician was very knowledgeable and provided a quick solution to my garage door issue."</h2>
                    <a href="Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair</a>
                    <h4>Jason S.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair is the company to call for garage door repairs. They are fast, reliable, and very professional."</h2>
                    <a href="Garage-Door-Residential">Garage Door Residential</a>
                    <h4>Maria K.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a great experience with Golden Garage Door Repair. The technician was prompt and fixed the issue in no time."</h2>
                    <a href="Garage-Door-Commercial">Commercial Garage Door</a>
                    <h4>Ethan T.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Super satisfied with the service. My garage door was stuck, and they came out the same day and fixed it. Great company!"</h2>
                    <a href="Garage-Door-Off-Track">Garage Door Off Track</a>
                    <h4>Cindy M.</h4>
                </div>

                <div className='ReviewsCustBox'>
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Golden Garage Door Repair did an amazing job. The service was fast, affordable, and very professional. I highly recommend them."</h2>
                    <a href="Garage-Door-Near-Me">Garage Door Near Me</a>
                    <h4>Greg L.</h4>
                </div>

            </div>

            <NavigatorPath />

        </div>
    )
}

